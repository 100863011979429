module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ali Waseem","short_name":"awaseem","start_url":"/","background_color":"#ffffff","theme_color":"#000","display":"standalone","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false,"wrapperStyle":"margin: 2rem auto;"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://aliw.ca"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
